var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe" }, [
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c("iframe", {
          attrs: {
            id: "iframe",
            src: _vm.go,
            frameborder: "0",
            width: "100%",
            height: _vm.height,
            scrolling: "auto",
          },
        }),
        _vm.loading
          ? _c("Spin", { attrs: { fix: "", size: "large" } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }